<template>
  <ion-page>
    <div v-if="isLoading" class="load-wrap ion-text-center">
      <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
      <p>Loading...</p>
    </div>
    <ion-header>
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button icon-only @click="$router.go(-1)">
            <ion-icon :icon="arrowBack"></ion-icon>
          </ion-button>
        </ion-buttons>
        <ion-title>{{ pin.title }}</ion-title>
      </ion-toolbar>
    </ion-header>

    <ion-content class="ion-no-padding ion-padding-top" fullscreen>
      <div v-if="!isLoading">
        <ion-item class="a-pin-detail__img-wrapper ion-no-padding --transparent">
          <div class="full-width ion-padding-horizontal">
            <ion-slides v-if="slidesRendered" :pager="true">
              <ion-slide v-for="(image, i) in images" class="full-width" :key="i" @click="openGalleryModal(i)">
                <img class="ion-margin-vertical" :src="image">
              </ion-slide>
              <ion-slide v-if="images.length == 0" class="full-width" key="placeholder">
                <div class="no-image ion-margin-vertical">
                  {{initials}}
                </div>
              </ion-slide>
            </ion-slides>
            <ion-skeleton-text v-else animated class="ion-margin-vertical" style="width: 100%; height: 200px"></ion-skeleton-text>
          </div>
        </ion-item>

        <ion-item class="ion-no-padding --transparent" button :detail-icon="caretForwardOutline"
                  @click="openCountry(pin.country?.id)">
          <div class="ion-padding-horizontal">
            <ion-label class="ion-padding-vertical">
              Country
              <div class="d-flex ion-align-items-center ion-margin-top">
                <img class="z-flag ion-margin-end" :src="pin.country?.flag.path" />
                <div>{{ pin.country?.title }}</div>
              </div>
            </ion-label>
          </div>
        </ion-item>

        <ion-grid class="ion-padding">
          <ion-row v-if="pin.remarks">
            <ion-col class="ion-justify-content-between ion-align-items-center">
              <ion-item class="ion-no-padding --transparent">
                <ion-label>
                  <div class="ion-margin-top">Remarks</div>
                </ion-label>
              </ion-item>
            </ion-col>
          </ion-row>
          <ion-row v-if="pin.remarks" class="ion-padding-bottom">
            <ion-col class="ion-justify-content-between ion-align-items-center">
              <span v-html="_linkify(pin.remarks)"></span>
            </ion-col>
          </ion-row>

          <ion-row class="ion-padding-bottom">

            <ion-col size="6" size-md="4" v-if="!pin.diameter" class="ion-justify-content-between ion-align-items-center">
              <z-summary :label="'Width (mm)'" :value="parseFloat(pin.width)"></z-summary>
            </ion-col>

            <ion-col size="6" size-md="4" v-if="!pin.diameter" class="ion-justify-content-between ion-align-items-center">
              <z-summary :label="'Height (mm)'" :value="parseFloat(pin.height)"></z-summary>
            </ion-col>

            <ion-col size="12" v-if="pin.diameter" class="ion-justify-content-between ion-align-items-center">
              <z-summary :label="'Diameter (mm)'" :value="parseFloat(pin.diameter)"></z-summary>
            </ion-col>

            <ion-col
                :size="pin.material?.title.length < breakLength ? 6 : 12"
                size-md="4"
                class="ion-justify-content-between ion-align-items-center">
              <z-summary :label="'Material'" :value="pin.material?.title"></z-summary>
            </ion-col>

            <ion-col size="6" size-md="4" class="ion-justify-content-between ion-align-items-center">
              <z-summary :label="'Production'" :value="pin.year_end ? `${pin.year} - ${pin.year_end}` : `${pin.year}`"></z-summary>
            </ion-col>

            <ion-col
                :size="pin.finish?.title.length < breakLength ? 6 : 12"
                size-md="4"
                class="ion-justify-content-between ion-align-items-center">
              <z-summary :label="'Finish'" :value="pin.finish?.title"></z-summary>
            </ion-col>

            <ion-col
                :size="pin.tacking?.title.length < breakLength ? 6 : 12"
                size-md="4"
                class="ion-padding-start ion-justify-content-between ion-align-items-center">
              <z-summary :label="'Fitting'" :value="pin.tacking?.title"></z-summary>
            </ion-col>

            <ion-col
                :size="pin.event?.title.length < breakLength ? 6 : 12"
                size-md="4"
                v-if="pin.event"
                class="ion-justify-content-between ion-align-items-center">
              <z-summary :label="'Purpose of issue'" :value="pin.event?.title"></z-summary>
            </ion-col>

            <ion-col size="6" size-md="4"  class="ion-justify-content-between ion-align-items-center">
              <z-summary :label="'Type'" :value="pin.official ? 'Official' : 'Unofficial'"></z-summary>
            </ion-col>

            <ion-col
                :size="pin.manufacturer?.title.length < breakLength ? 6 : 12"
                size-md="4"
                class="ion-justify-content-between ion-align-items-center">
              <z-summary :label="'Manufacturer'" :value="pin.manufacturer?.title"></z-summary>
            </ion-col>

            <ion-col size="6" size-md="4"></ion-col>
          </ion-row>
        </ion-grid>

        <div class="ion-padding-horizontal bottom">
          <ion-button :class=" pin.in_collection ? '--default --success' : '--default --danger'"
                      @click="addOrRemovePinFromCollection(pin.in_collection ? 'removePin' : 'addPin')">
            {{ pin.in_collection ? 'Remove from my collection' : 'Add to my collection' }}
          </ion-button>
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import { modalController, IonSlides, IonSlide, IonSkeletonText } from '@ionic/vue'
  import { arrowBack, caretForwardOutline } from 'ionicons/icons'
  import wAxios from '@/plugins/w/axios'
  import GalleryModal from '@/plugins/app/pin-details/gallery-modal.vue'
  import ZSummary from '@/plugins/app/_components/z-summary.vue'

  export default defineComponent({
    components: {
      IonSlides, IonSlide, IonSkeletonText, ZSummary
    },

    watch: {
      $route (){
        if (this.$route.params.id && this.$route.fullPath.includes('pin-detail'))
        {
          this._fetchPin()
        }
      }
    },

    mounted() {
      this._fetchPin()
      this._handleSlidesRendering()
    },

    data() {
      return {
        pin: {},
        breakLength: 13,
        isLoading: false,
        slidesRendered: false,
        arrowBack,
        caretForwardOutline,
      }
    },

    computed: {
      ...mapGetters('wAuth', ['isLoggedIn']),

      images() {
        if (!this.pin.images)
          return []
        return Object.values(this.pin.images).map((image: any) => image.path)
      },

      initials() {
        return this.pin.title.substring(0, 2)
      }
    },

    methods: {
      ...mapActions('collection', ['removePin', 'addPin']),

      addOrRemovePinFromCollection(action) {
        if (!this.isLoggedIn) {
          return this.$router.push({ path: '/app/login' })
        }

        // add or remove
        this[action](this.pin.id)
        this.$store.commit('catalogue/inCollectionChanged', {pinId: this.pin.id, value: action == 'addPin'})
        this.$store.commit('countries/inCollectionChanged', {pinId: this.pin.id, value: action == 'addPin'})
        this.pin.in_collection = !this.pin.in_collection
      },

      async openCountry(countryId) {
        this.$router.push(`/app/country-detail/${countryId}`)
      },

      async openGalleryModal(selectIndex) {
        const modal = await modalController.create({
          component: GalleryModal,
          componentProps: { images: this.images, selectIndex },
          cssClass: 'modal-all'
        })
        return modal.present()
      },

      async _fetchPin() {
        try {
          this.isLoading = true
          this.pin = await wAxios.get_auth(`v1/pins/${this.$route.params.id}`)
          this.isLoading = false
        } catch (err) {
          console.error(err)
        }
      },

      // WORKAROUND for slides rendering. Whole screen is not rendered without this
      _handleSlidesRendering() {
        setTimeout(() => this.slidesRendered = true, 500)
      },

      _linkify(inputText) {
        //URLs starting with http://, https://, or ftp://
        const replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#/%?=~_|!:,.;]*[-A-Z0-9+&@#/%=~_|])/gim
        return inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>')
      },
    }
  })
</script>

<style lang="sass" scoped>
.no-image
  background: radial-gradient(60.77% 60.77% at 50% 50%, #FFFFFF 0%, #FFFFFF 100%)
  border: 2px solid rgba(189, 189, 189, 0.2)
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1)
  border-radius: 12px
  width: 40%
  max-height: 200px
  line-height: 140px
  margin: 50px auto
  font-size: 40px
  color: rgba(189, 189, 189, 0.6)

ion-slide
  img
    --ion-margin: 50px
    max-height: 200px
    width: auto

ion-skeleton-text
  --ion-margin: 50px

.a-pin-detail
  &__img-wrapper
    --ion-safe-area-right: 0
    --inner-padding-end: 0
    --background: transparent
ion-col
  padding: 0 15px
  margin-bottom: 20px

.load-wrap
  padding-top: 40vh
  width: 100%
  height: 100vh
  position: fixed
  background: rgba(255,255,255, 0.8)
  z-index: 10000
.bottom
  margin-bottom: 100px
</style>
