
  import { defineComponent } from 'vue'
  import { closeOutline } from 'ionicons/icons'
  import { modalController } from '@ionic/vue'

  export default defineComponent({
    props: {
      images: {type: Array, required: true},
      selectIndex: {type: Number, default: 1}
    },
    data() {
      return {
        closeOutline,
        index: 1
      }
    },
    mounted() {
      this.index = this.selectIndex
    },
    methods: {
      closeModal() {
        modalController.dismiss()
      },
    }
  })
